@font-face {
  font-family: websoft_text;
  src: url(../../WebSoft_text.otf);
}

@font-face {
  font-family: websoft_title;
  src: url(../../WebSoft.TTF);
}

.portfolio {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  /* margin-top: 100px !important; */
  height: 520px;
}

.port_header {
  /* height: 236px; */
margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.port_header h3 {
  margin-top: 100px;
  font-style: normal;
  font-size: 48px;
  line-height: 58px;
  color: #1E4078;
  font-family: websoft_title;
}

.port_boxes {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.portfolio_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  height: 500px;
  border: 1px solid #1e4078;
  border-radius: 4px;
  padding: 30px;
}

.portfolio-slider-cont {
  /* width: 80%; */
  height: 80%;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
  
}

.portfolio_box h3 {
  width: 326px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  font-family: websoft_text;
  /* margin-top: 30px; */
}

.btn {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  font-family: websoft_text;
  color: #FDFDFF;
  cursor: pointer;
}

.btn button {
  background-color: #1e4078;
  color: white;
  border: none;
  border-radius: 4px;
  width: 210px;
  height: 54px;
  font-size: 18px;
  margin: 0px;
  cursor: pointer;
}

.btn button:hover {
  background: linear-gradient(
    90deg,
    #1c4787 -2.41%,
    #429eb9 46%,
    #1b7f9d 85.62%,
    #1b7f9d 80.4%,
    #1b7f9d 96.28%
  );
  
}

.img-comp {
  background-size: auto;
  width: 389px;
  height: 171px;
}




/* @media screen and(min-width:1350px) {
  .portfolio_box{
    width: 400px !important;
    height: 450px !important;
  }
  .img-comp{
    width: 250px !important;
    height: 100px !important;
  }
  
} */



@media screen and (max-width:1024px) {
.portfolio-slider-cont {
  /* width: 80%; */
  height: 80%;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding-left: 5px;
  padding-right: 5px;
}
.portfolio_box h3 {
  width: 90%;
  font-size: 17px;
  margin-bottom: 8px;
}

.portfolio_box {
  padding: 15px;
}
.btn{
  margin-top: 55px;
  margin-bottom: 70px;
}
.portfolio{height: 420px;}

}


@media screen and (max-width:900px) {
  .port_header h3 {
    margin-top: 60px;
  }
  .portfolio{
    height: 500px;
  }
  .portfolio_box {
    padding: 5px;
  }
}
@media screen and (max-width:750px) {
 
  .portfolio{
    height: 350px;
  }
 
}

@media screen and (max-width:768px) {
 
  .btn{margin-top: 10px;}
  .portfolio{height: 440px;}
 
}

@media screen and (max-width:578px) {
  .port_header h3 {
    margin-top: 3px;
  }
  .port_logo{
    width: 55px!important;
  }
  .port_header h3{font-size: 22px;}
  .port_header{margin-bottom: 0px;}
  .btnport {margin-top: -16px;}
  .btn{margin-bottom: 20px;}
  .btn button {
    background-color: #1e4078;
    color: white;
    border: none;
    border-radius: 4px;
    width: 117px;
    height: 30px;
    font-size: 13px;
    margin: 30px 0px 40px;

}
 
}
@media screen and (max-width:450px) {
  .portfolio{
    height: 170px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width:430px) {
  .btn{
    margin-top: 15px;
  }
}
@media screen and (max-width:425px) {
  .btn button {margin: 0px 0px 30px;}
}


@media screen and (max-width:393px) {
  .portfolio-slider-cont {
    width: 89%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (max-width:390px) {
  .portfolio-slider-cont {
    width: 89%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (max-width:360px) {
  .portfolio-slider-cont {
    width: 89%;
    padding-left: 0px;
    padding-right: 0px;
  }
}



@media screen and (max-width:375px) {
  .portfolio-slider-cont {
    width: 90%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .btn button {margin: 0px 0px 35px;}

}



@media screen and (max-width:348px) {
  .portfolio{
    height: 210px;
  }
 
  .portfolio_box {
    padding: 10px 5px;
    margin: 5px;
  }
}

@media screen and (max-width:320px) {
  .portfolio-slider-cont {
    width: 100%;
    padding-left: 6px;
    padding-right: 15px;
}

}
