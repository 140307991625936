.upper {
  height: 85px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  white-space: nowrap;
  /* margin-right: 56px; */
  /* overflow: hidden; */
  
}
.cont{
  margin: auto;
  max-width: 1440px;
  padding-left: 30px;
  padding-right: 30px;

}
.title-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-img {
  width: 230px;
  height: 180px;
  pointer-events: none;
 
}
.cont-info {
  display: flex;
  align-items: center;
}
.upper .phone {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upper .phone .icona {
  color: #1e4078;
  width: 19px;
  height: 19px;
  margin-right: 10px;
}
.upper .phone p , .upper .phone a{
  font-size: 18px;
  color: #1e4078;
  font-weight: 700;
}

.upper .message {
  margin-left: 25px;
  display: flex;
  align-items: center;
}
.upper .message .iconam {
  color: #1e4078;
  margin-right: 10px;
}

.upper .message p , .upper .message a{
  font-size: 18px;
  color: #1e4078;
  font-weight: 700;
}
.dropdown {
  display: none;
}
.dropdown select{
  outline: none;
}

@media screen and (max-width: 1007px) {
  .cont-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .dropdown {
    display: block;
  }
  .dropdown select {
    /* margin-bottom: 1em; */
    border: 0;
    font-weight: bold;
    letter-spacing: 0.15em;
    border-radius: 0;
    color: rgba(27, 127, 157, 1);
  }
}
@media screen and (max-width: 840px) {
  .upper .phone p, .upper .phone a {
    font-size: 15px;
  }
  .message p {
    font-size: 15px;
  }
  
  img.icon-img {
    width: 200px;
    height: 150px;
  }
  .cont-info .phone p {
    font-size: 14px;
    /* margin-right: 150px; */
    /* padding: 20px 0; */
  }
}
@media screen and (max-width: 767px) {
  .cont-info .phone {width: 140px;}
  .cont-info .phone p, .cont-info .message a, .cont-info .phone a {
    font-size: 14px;
  }
  .upper .message {margin-left: 0px;}
  .cont-info {gap: 8px;}
  .upper .message .iconam {margin-right: 5px;}
  .upper .phone .icona {margin-right: 5px;}
  .dropdown {
    margin-left: 0px;
    font-size: 10px;
    display: flex;
    align-items: center;
  }
 
  
  .upper {
    margin-right: 255px;
    gap: 10px;
  }
  img.icon-img {
    width: 170px;
    height: 130px;
  }
}
@media screen and (max-width: 667px) {
  .upper {
    margin-right: 145px;
  }
  img.icon-img {
    /* width: 180px;
    height: 140px; */
    margin-left: -12px;
  }
  .cont-info .message{
    margin: 0;
  }
}
@media screen and (max-width: 622px){
.nav ul li a{
padding: 5px;
}

.cont-info{
  margin-left: -20px;
}
}
@media screen and (max-width: 580px){
  .dropdown {
    margin-left: 10px;
    font-size: 10px;
    display: flex;
    align-items: center;
}
  .upper{
    height: 60px;
  }
  .upper .message p, .upper .message a {
    font-size: 14px;
  }

  img.icon-img {
    margin-left: -28px;
}
  .cont-info{
    margin-left: 10px;
  }
  .cont-info {
    gap: 15px;
}
.mobile-version {
    margin-bottom: -5px !important;
    margin-top: -20px;
}


} 

@media screen and (max-width: 579px){
  img.icon-img {
    display: none;
  } 
}


@media screen and (max-width: 550px) {
  .upper {
    margin-right: 125px;
  }
  img.icon-img {
    width: 170px;
    height: 120px;
  }
  .cont-info .phone p, .phone a {
    font-size: 13px;
  }
  .mobimage{
    margin-left: 20px;
  }
}
@media screen and (max-width: 490px) {
  .upper .message .iconam {
    width: 17px;
    margin-right: 4px;
}
  .upper {
    margin-right: 105px;
  }
  img.icon-img {
    width: 150px;
    height: 100px;
    margin: 0;
  }
  .cont-info .phone p , .phone a{
    font-size: 12px;
  }
  .cont-info .message p, .message a {
    font-size: 12px;
  }
  .hamburger {
    height: 30px;
  }
  .cont-info{
    margin-left: 1px;
    gap: 0px;
  }
  .upper .phone .icona {
    width: 17px;
    margin-right: 5px;
} 
  
  }

  @media screen and (max-width: 430px) {
    .cont-info{gap: 20px;}
    .cont-info .phone { margin-left: -20px;}
    .upper .message p, .upper .message a {font-size: 15px;}
    a, .cont-info .phone a {
      font-size: 15px;
  }

  }



  @media screen and (max-width: 425px) {
    .cont-info {
      margin-left: -1px;
      gap: 9px;
  }
  .cont-info .phone {
    width: 160px;
    margin-left: -20px;
}
.cont {padding-right: 25px;}


}


  

  @media screen and (max-width: 400px) {
    .cont-info {
      margin-left: -1px;
      gap: 5px;
  }
  .cont-info .phone {
    width: 160px;
    margin-left: -20px;
}
.cont {padding-right: 25px;}
.mobile-version {
  margin-bottom: -30px !important;
  margin-top: -10px;
}


}



@media screen and (max-width: 390px) {
 
  .cont-info{
    gap: 10px;
  }
 .cont-info .phone{
  width: 127px;
 }
 .cont-info{
  margin-left: 12px;
 }
 .upper .message .iconam{
  width: 18px;
  height: 18px;
  margin-right: 3px;
 }
 .upper .phone .icona{
  width: 18px;
  height: 18px;
  margin-right: 6px;
 }

}

@media screen and (max-width: 375px) {
    .cont-info {
      margin-left: -1px;
      gap: 1px;
  }
  .cont-info .phone {
    width: 137px;
    margin-left: -4px;
  }
  .cont {padding-right: 25px;}

}


@media screen and (max-width: 360px) {
  .phone {
    right: 80px;
  }
}
@media screen and (max-width: 360px) {
  .phone {
    right: 60px;
  }
  .hamburger {
    width: 15px;
  }
  img.icon-img {
    width: 170px;
    height: 130px;
    margin: 0;
  }
  .upper .phone .icona {
    width: 15px;
    height: 15px;
    margin-right: 4px;
}

.upper .message p, .upper .message a {
  font-size: 13px;
}
.cont-info .phone a {
  font-size: 13px;
}

.cont-info .phone {
  width: 125px;
  margin-left: -4px;
}


}
@media screen and (max-width: 341px){
  .dropdown{
    margin-left: -2px;
  }
  .dropdown select{
    font-size: 10px;
  }
  .dropdown img{
    width: 5px;
    height: 5px;
  }
  .cont-info{
    gap: 8px;
  }

}

@media screen and (max-width: 320px){
  .cont-info .phone a {
    font-size: 12px;
  }
  .upper .message p, .upper .message a {
    font-size: 12px;
}
  .upper .phone .icona {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
  .cont-info .phone {
    width: 150px;
    margin-left: 15px;
}
.cont-info {
  margin-left: -13px;
}

}