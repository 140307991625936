@font-face {
  font-family: websoft_title;
  src: url(../../WebSoft.TTF);
}
@font-face {
  font-family: websoft_text;
  src: url(../../WebSoft_text.otf);
}
.design_main {
  background-image: url("/public/images/bc.webp");
  height: 420px;
  position: relative;
  margin-top: 80px;
}

.design_main .elements {
  height: 420px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.design-h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0em;
  margin-top: 20.65px;
  color: #100F0F;
  font-family: websoft_title;

}

.half-circle img {
  height: 480px;
  margin-top: -29px;
}

.half-circle-1 img {
  height: 480px;
  margin-top: -29px;
}

.projects {
  /* width: 256px; */
  text-align: center;
}

.round {
  width: 256.25px;
  height: 256.25px;
  border-radius: 50%;
  background: rgba(27, 127, 157, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-1 {
  width: 214.18px;
  height: 214.18px;
  border-radius: 50%;
  background: rgba(234, 254, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 183.37px;
  height: 183.37px;
  border-radius: 50%;
  background: rgba(30, 64, 120, 1);

}

.round-3 {
  background-color: #fff;
  width: 160.5px;
  height: 160.5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nine {
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  color: #100F0F;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: websoft_text;
}

.half-circle {
  height: 420px;
  overflow: hidden;

}

.half-circle-1 {
  height: 420px;
  overflow: hidden;
}


/* 

@media screen and (max-width:1060px) {

  .round {
    width: 236px;
    height: 236px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 194px;
    height: 194px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 163px;
    height: 163px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 55px;
  }
} */





@media screen and (max-width:1024px) {

  .round {
    width: 206px;
    height: 206px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 174px;
    height: 174px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 143px;
    height: 143px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 45px;
  }

  .design-h2 {
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    margin-top: 22.65px;
  }
}







@media screen and (max-width:1100px) {

  .round {
    width: 206px;
    height: 206px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 174px;
    height: 174px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 143px;
    height: 143px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 45px;
  }

  .design-h2 {
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    margin-top: 22.65px;
  }
}
/* 
@media screen and (max-width:1000px) {

  .round {
    width: 206px;
    height: 206px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 174px;
    height: 174px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 143px;
    height: 143px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 35px;
  }

  .design-h2 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: 22.65px;
  }

  .experiance {
    margin-right: -120px;
    z-index: 1;
  }

  .projects {
    margin-left: -120px;
  }
} */

@media screen and (max-width:992px) {

  .round {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 135px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 35px;
  }

  .design-h2 {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 22.65px;
  }

  .experiance {
    margin-right: -100px;
    z-index: 1;
  }

  .projects {
    margin-left: -100px;
  }

  .elements {
    height: 380px;
  }

  .half-circle {
    margin-left: -30px;

  }

  .half-circle-1 {
    margin-right: -30px;

  }

  .design_main {
    height: 380px;
  }

  .half-circle img {
    height: 493px;
    margin-top: -55px;
  }

  .half-circle-1 img {
    height: 493px;
    margin-top: -55px;
  }
}







@media screen and (max-width: 820px) {

  .round {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 135px;
    height: 135px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 30px;
  }

  .design-h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 22.65px;
  }

  .experiance {
    margin-right: -80px;
    z-index: 1;
  }

  .projects {
    margin-left: -80px;
  }
  .design_main{
    margin-top: 60px;
  }
}












@media screen and (max-width: 800px) {

  .round {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 135px;
    height: 135px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 30px;
  }

  .design-h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 22.65px;
  }

  .experiance {
    margin-right: -80px;
    z-index: 1;
  }

  .projects {
    margin-left: -80px;
  }
  .design_main{
    margin-top: 60px;
  }
}







@media screen and (max-width:768px) {

  .round {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 115px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 103px;
    height: 103px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 35px;
  }

  .design-h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 22.65px;
  }

  .experiance {
    margin-right: -60px;
    z-index: 1;
  }

  .projects {
    margin-left: -60px;
  }

  .elements {
    height: 350px;
  }

  .half-circle {
    margin-left: -50px;

  }

  .half-circle-1 {
    margin-right: -50px;

  }

  .design_main {
    height: 350px;
  }

  .half-circle img {
    height: 511px;
    margin-top: -78px;
  }

  .half-circle-1 img {
    height: 511px;
    margin-top: -78px;
  }
}










@media screen and (max-width:736px) {

  .round {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 115px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 103px;
    height: 103px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 35px;
  }

  .design-h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 22.65px;
  }

  .experiance {
    margin-right: -60px;
    z-index: 1;
  }

  .projects {
    margin-left: -60px;
  }

  .elements {
    height: 350px;
  }

  .half-circle {
    margin-left: -50px;

  }

  .half-circle-1 {
    margin-right: -50px;

  }

  .design_main {
    height: 350px;
  }

  .half-circle img {
    height: 511px;
    margin-top: -78px;
  }

  .half-circle-1 img {
    height: 511px;
    margin-top: -78px;
  }
}







@media screen and (max-width:700px) {

  .round {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 115px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 103px;
    height: 103px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 35px;
  }

  .design-h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 22.65px;
  }

  .experiance {
    margin-right: -60px;
    z-index: 1;
  }

  .projects {
    margin-left: -60px;
  }

  .elements {
    height: 350px;
  }

  .half-circle {
    margin-left: -50px;

  }

  .half-circle-1 {
    margin-right: -50px;

  }

  .design_main {
    height: 350px;
  }

  .half-circle img {
    height: 511px;
    margin-top: -78px;
  }

  .half-circle-1 img {
    height: 511px;
    margin-top: -78px;
  }
}

@media screen and (max-width:600px) {

  .round {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 105px;
    height: 105px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 35px;
  }

  .design-h2 {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 22.65px;
  }

  .experiance {
    margin-right: 0px;
    z-index: 1;
  }

  .projects {
    margin-left: 0px;
  }

  .elements {
    height: 280px;
  }

  .half-circle {
    margin-left: -50px;

  }

  .half-circle-1 {
    margin-right: -50px;

  }

  .design_main {
    height: 280px;
  }

  .half-circle img {
    height: 436px;
    margin-top: -17px;
  }

  .half-circle-1 img {
    height: 436px;
    margin-top: -17px;
  }

  .design_main .elements {
    height: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .design_main{
    margin-top: 40px;
  }
}

@media screen and (max-width:464px) {

  .round {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 25px;
  }

  .design-h2 {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    margin-top: 10.65px;
    margin-bottom: 60px;
  }

  .experiance {
    margin-right: 0px;
    z-index: 1;
  }

  .projects {
    margin-left: 0px;
  }

  .elements {
    height: 200px;
  }

  .half-circle {
    margin-left: 0px;
    height: 154px;

  }

  .half-circle-1 {
    margin-right: 0px;
    height: 154px;
  }

  .design_main {
    height: 150px;
  }

  .half-circle img {
    height: 164px;
    margin-top: -7px;
  }

  .half-circle-1 img {
    height: 164px;
    margin-top: -7px;
  }

  .design_main .elements {
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}


@media screen and (max-width:464px) {

  .round {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 71px;
    height: 71px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 22px;
  }

  .design-h2 {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    margin-top: 10.65px;
    margin-bottom: 5px;
  }

  .experiance {
    margin-right: 0px;
    z-index: 1;
  }

  .projects {
    margin-left: 0px;
  }

  .elements {
    height: 150px;
  }

  .half-circle {
    margin-left: -10px;

  }

  .half-circle-1 {
    margin-right: -10px;

  }

  .design_main {
    height: 150px;
  }

  .half-circle img {
    height: 185px;
    margin-top: -15px;
    margin-right: 0px;
   
  }

  .half-circle-1 img {
    height: 185px;
    margin-top: -15px;
    margin-left: 0px;
 
  }

  .design_main .elements {
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .design_main{
    margin-top: 20px;
    margin-bottom: 40px;
  }
}



@media screen and (max-width:320px) {

  .round {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-color: #1B7F9D;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-1 {
    width: 71px;
    height: 71px;
    border-radius: 50%;
    background-color: #EAFEFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .round-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-color: #1E4078;

  }

  .round-3 {
    background-color: #fff;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nine {
    font-size: 16px;
  }

  .design-h2 {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    margin-top: 6.65px;
    margin-bottom: 5px;
  }

  .experiance {
    margin-right: 0px;
    z-index: 1;
  }

  .projects {
    margin-left: 0px;
  }

  .elements {
    height: 150px;
  }

  .half-circle {
    margin-left: -10px;

  }

  .half-circle-1 {
    margin-right: -10px;

  }

  .design_main {
    height: 150px;
  }

  .half-circle img {
    height: 185px;
    margin-top: -15px;
    margin-right: 0px;
   
  }

  .half-circle-1 img {
    height: 185px;
    margin-top: -15px;
    margin-left: 0px;
 
  }

  .design_main .elements {
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .design_main{
    margin-top: 20px;
    margin-bottom: 40px;
  }
}