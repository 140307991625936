@font-face {
    font-family: websoft_title;
    src: url(../../WebSoft.TTF);
  }
.collegues-cont {
    margin-top: 100px;
    /* height: 287px; */
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    position: relative;
    flex-direction: row;
}




.collegues-slider-blok {
    height: 200px;
    width: 100%;
    margin-top: 40px;
}

.h3-coll {
    color: #1E4078;
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 70px;
    font-family: websoft_title;
    /* margin-right: 50px; */

}
@media screen and (min-width: 300px) {
    .h3-coll{
        font-size: 22px;
        margin-top: -20px;
    
    }
    .collegues-slider-blok{
        height: 120px;
        margin-top: -15px;
    }
    .collegues-cont{
        margin-top: 70px;
    }
    /* Dot Start */
    .collegues-slider-blok  .swiper-pagination-bullet{
        border: 1px solid #1E4078!important;
      }
      .collegues-slider-blok .swiper-pagination-bullet-active{
        /* background: #1E4078 !important; */
      }
      /* Dot End  */
}

@media screen and (min-width: 768px) {
    .collegues-cont{margin-top: 0px !important;}
}

@media screen and (min-width: 600px) {
    .h3-coll{
        font-size: 30px;
    }
    .collegues-slider-blok{
        height: 150px;
        margin-top: 10px;
    }
    .collegues-cont {
        margin-top: 60px;
    }

}
@media screen and (min-width: 1040px) {
    .h3-coll{
        font-size: 48px;
      }
    .collegues-slider-blok{
        height: 251px;
        margin-top: 10px;
    }
    .collegues-cont {
        margin-top: 100px;
    }

}